import { useContext, useEffect, useState } from 'react';
import styles from './successPage.module.scss';
import { CartContext } from '../../config/context/CartContext';
import { emailTriggerApi, getOrderDetails, getOrderDetailsFromOrderId } from '../../config/axios/orderApis';
import { toast } from '../../components/Toaster/ToasterComponent';

const SuccessPage = () => {
  
const [orderDetails,setOrderDetails] = useState<any>();
const [productList, setProductList] = useState<any>();

  useEffect(() => {
    getOrderDetailsFromOrderId(localStorage.getItem('placedOrderId')!).then((response:any) => {
      console.log("Response",response);
      setOrderDetails(response.data);
    })
    emailTriggerApi(localStorage.getItem('userId')!,localStorage.getItem('placedOrderId')!,localStorage.getItem('addressId')!).then((emailResponse:any) => {
      if(emailResponse.status === 200){
        toast.success('Email has been sent for order details')
      }
      else{
        toast.error('Error sending email for order details')
      }
    })
  },[])

  useEffect(() => {
    if(orderDetails){
      console.log("Product List", productList);
     setProductList(orderDetails?.productList);
    }
  },[orderDetails])

  return (
    <div className={styles.success_container}>
        <div className={styles.order_details_container}>
        <div className={styles.order_details_header}>Thank you for your order</div>
        <div className={styles.order_details_sub_text}>
        <div>Your order has been received and now is being processed.</div>
        <div className={styles.order_data}>Order #{orderDetails?.orderId}</div>
        <div className={styles.order_details_table}>
          <div>
            <div>Product</div>
            <div>Quantity</div>
            <div>Price</div>
          </div>
          {productList && productList?.length > 0 && productList.map((productDetails:any) => <div>
            <div>{productDetails.productName}</div>
            <div>{productDetails.quantity}</div>
            <div>{`\u00A3`} 0.25</div>
          </div>)}
          <div>
            <div>SubTotal</div>
            <div>{`\u00A3`} 0.25</div>
          </div>
          <div>
            <div>Shipping Fee</div>
            <div>{`\u00A3`} {orderDetails?.totalPrice < 35 ? '5.00' : '0.00'}</div>
          </div>
          <div>
            <div>Payment Method</div>
            <div>Direct Bank Transfer</div>
          </div>
          <div>
            <div>Total</div>
            <div>{`\u00A3`} {orderDetails?.totalPrice}</div>
          </div>
        </div>
        <div className={styles.billing_address_heading}>Billing Address</div>
        <div className={styles.billing_address_details}>John Doe <br />
        Street <br />
        City <br />
        State <br />
        Zip Code <br />
        Country
        </div>
        </div>
      </div>

    {/* You can add more details or actions here */}
  </div>
  )
}

export default SuccessPage