import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getBlogEntry } from '../../config/axios/blogApis';
import styles from './blogPage.module.scss';
import { BlogType } from './BlogPage';
import { ArrowLeftOutlined } from '@ant-design/icons';

const BlogEntry = () => {

  const {blogId} = useParams();
  const navigate = useNavigate();

  const [blogContents, setBlogContents] = useState<BlogType>({
    id: 0,
    title: '',
    description: '',
    imageUrl: ''
  })

  useEffect(() => {
    if(blogId){
      getBlogEntry(blogId).then((response:any) => {
        setBlogContents({
          id: response.data.blogId,
          title : response.data.blogTitle,
          description: response.data.blogDescription,
          imageUrl: response.data.blogImageUrl
        })
      })
    }
  },[blogId])

  return (
    <div className={styles.blog_entry_wrapper}>
        <div className={styles.blog_entry_heading_container}>
          <ArrowLeftOutlined className={styles.blog_entry_back_button} onClick={() => navigate(-1)}/>
          <div className={styles.blog_entry_title}>{blogContents.description}</div>
        </div>
       
        <img src={blogContents.imageUrl} alt='Blog Entry Image' className={styles.blog_entry_image} />
        
        <div className={styles.blog_content}>{blogContents.title}</div>
    </div>
  )
}

export default BlogEntry