import axios from "axios";

export const getAllPromos = async () => {
    try{
        return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/promo/all`)
      }catch(err){
        return err;
    }
}

export const validatePromo = async (promoCode:string,productIds:number[]) => {

    const productIdArray = productIds.map((productId) => `productId=${productId}`)
    const queryParam = productIdArray.toString().split(",").join("&");

    try{

        return await axios.post(`${process.env.REACT_APP_ARRFINE_BASE_URL}/discountprice/${promoCode}?${queryParam}`,{})

    }catch(err){
        return err;
    }


}

export const getPromoCodeById = async (promoCode:number) => {
    try{
        return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/promo/${promoCode}`)
      }catch(err){
        return err;
    }
}