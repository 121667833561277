
import {Button, Checkbox, Input, Pagination, Slider, SliderSingleProps, Spin } from 'antd';
import CategoryCarousal from '../HomePage/CategoryCarousal';
import styles from './categoriesPage.module.scss';
import ProductListing from '../ProductListing/ProductListing';
import TeaBox from '../../assets/images/image 48_taj_mahal.png';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ProductListProps, SaleDetails } from '../HomePage/HomePage';
import { getListofProducts, getProductById, getProductCategoryById, getProductsInCategory } from '../../config/axios/productApis';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { useLocation } from 'react-router-dom';
import { getAllSale } from '../../config/axios/saleApis';
import { ProductContext } from '../../config/context/ProductContext';



const CategoriesPage = () => {

  const location = useLocation();
  const catId = location?.state;
  const[categoryId, setCategoryId] = useState<string>('');
  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');
  const [pageSize,setPageSize] = useState<number>(12);
  const [filteredArray,setFilteredArray] = useState<ProductListProps[]>([]);
  const [saleListData,setSaleListData] = useState<SaleDetails[]>([]);
  const [filterToggle,setFilterToggle] = useState<boolean>(false);

  const {addProduct} = useContext(ProductContext);


  const [productList, setProductList] = useState<ProductListProps[]>([{
    imageUrl: '',
    title: '',
    description: '',
    price: 0,
    id: '',
  }])

  const [paginationData, setPaginationData] = useState<ProductListProps[]>([
    {imageUrl: '',
    title: '',
    description: '',
    price: 0,
    id: '',}
  ])

  const [filteredPaginationData, setFilteredPaginationData] = useState<ProductListProps[]>([
    {imageUrl: '',
    title: '',
    description: '',
    price: 0,
    id: '',}
  ])

  const pageSizeOptions = [
    '12','24','36','48'
  ]

  const pageHandler = (page: number,pageSize:number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  const [page, setPage] = useState<number>(1);
  const [categoryListLoader, setCategoryListLoader] = useState<boolean>(false);

  useEffect(() => {
    setPage(1);
    clearData();
    if(categoryId !== ''){
    setCategoryListLoader(true);
    getProductCategoryById(categoryId).then((productCategory:any) => {
      if(productCategory && productCategory.data.arrayOfProduct !== null){
        const valueArray: any[] = [];
        const productArray = JSON.parse(productCategory.data.arrayOfProduct);
        Promise.all(
          productArray.map((productId: number) => 
            getProductById(productId.toString()).then((product: any) => {
              const productData = product.data;
              return {
                title: productData.productName,
                description: productData.description,
                price: productData.price,
                id: productData.productId,
                imageUrl: productData.productImage
              };
            })
          )
        ).then((results) => {
          valueArray.push(...results);
          setProductList(valueArray);
          setCategoryListLoader(false);
        }).catch((error) => {
          console.error("Error fetching products:", error);
        });
        
    }else{
      setProductList([]);
    }
    })
  }
  },[categoryId])

  useEffect(() => {
    if(catId && catId !== ''){
     
      if(catId === 'sale'){
        saleHandler();
      }
      else{
      setCategoryId(catId);
      }
    }
  },[catId])

  useEffect(() => {
    if(productList.length > 0){
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginationData(productList.slice(startIndex, endIndex));
    }
  },[page,productList,pageSize])

  useEffect(() => {
    if(filteredArray.length > 0){
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setFilteredPaginationData(filteredArray.slice(startIndex, endIndex));
      }
    else{
      setFilteredPaginationData([])
    }
  },[page,filteredArray,pageSize])


  const getCategoryName  = (categoryName: string) => {setCategoryId(categoryName)}

  useEffect(() => {
    setCategoryListLoader(true);
    if(categoryId === ''){ 
    getProductCategoryById('20').then((productCategory:any) => {
      if(productCategory && productCategory.data.arrayOfProduct !== null){
        const valueArray: any[] = [];
        const productArray = JSON.parse(productCategory.data.arrayOfProduct);
        Promise.all(
          productArray.map((productId: number) => 
            getProductById(productId.toString()).then((product: any) => {
              const productData = product.data;
              return {
                title: productData.productName,
                description: productData.description,
                price: productData.price,
                id: productData.productId,
                imageUrl: productData.productImage
              };
            })
          )
        ).then((results) => {
          valueArray.push(...results);
          setProductList(valueArray);
          setCategoryListLoader(false);
        }).catch((error) => {
          console.error("Error fetching products:", error);
        });
        
    }else{
      setProductList([]);
      setCategoryListLoader(false);
    }
    })
  }
  },[])

  const saleHandler = async () => {
    const response:any = await getAllSale();
    const newSaleList = await Promise.all(
      response?.data?.map(async (sale: any) => {
        const productList = await Promise.all(
          sale?.productsId?.map((productId: number) => 
            getProductById(productId.toString()).then((productResponse: any) => {
              const productData = productResponse.data;
              const contextObject = {
                productId: productData.productId,
                isSale: true,
                discountPrice: sale.discount
              }
              addProduct(contextObject);
              return {
                title: productData.productName,
                description: productData.description,
                price: productData.price,
                id: productData.productId,
                imageUrl: productData.productImage,
              };
            })
          )
        );
        return {
          saleId: sale.saleId,
          saleName: sale.saleName,
          discount: sale.discount,
          productList: productList,
        };
      })
    );
  
    console.log('New Sale List',newSaleList)
  
    // After processing all sales and products, update the state once
    setSaleListData(newSaleList);
  };

  useEffect(() => {
    filterData();
  },[minPrice,maxPrice])

  const filterData = () => {
    if(minPrice === '' && maxPrice === ''){
      return;
    }
    setFilterToggle(true);
    setCategoryListLoader(true);
     getProductsInCategory(categoryId,{
      minPrice: minPrice,
      maxPrice: maxPrice,
    },100).then((filteredResponse:any) => {
      if(filteredResponse.data.empty){
        setFilteredArray([]);
        setCategoryListLoader(false);
      }
      else{
      const resultArray = filteredResponse.data.content.map((product:any) => {
        return {
          title: product.productName,
          description: product.description,
          price: product.price,
          id: product.productId,
          imageUrl: product.productImage
        }
      })
      setFilteredArray(resultArray);
      setCategoryListLoader(false);
    }
    })
  }

  const minMaxPriceHandler = (value:number | number[]) => {
    if(Array.isArray(value)){
    setMinPrice(value[0].toString())
    setMaxPrice(value[1].toString())
    }
  }

  const clearData = () => {
    setMinPrice('');
    setMaxPrice('');
    setFilteredArray([]);
  }

  const marks: SliderSingleProps['marks'] = {
    0: '£0',
    100: '£100',
    200: '£200'
  }

  return (
   <div className={styles.categories_page_container}>
    <div className={styles.categories_header_container}>
        <div className={styles.veggies_text}>
                    <div>Shop Like a Hero <b>Fresh,Healthy Triumphs Await!</b></div>
                    <div>Unleash the Shopper's Valor: Where Every Cart is a chariot and Every Fresh,Healthy purchase a Triumph</div>
        </div>
    </div>
    <CategoryCarousal isHeaderNeeded={false} callbackFunction={(categoryName:string) => getCategoryName(categoryName)}/>
    { <div className={styles.categories_container}>
      { categoryId !== '' && <div className={styles.filter_checkbox_container}>
        <div>
          <div><b>Price Range</b></div>
        
           {/* <div>
             <label>Min price</label>
             <Input type='number' min={0} value={minPrice} onChange={(e) => {setMinPrice(e.target.value)}}/>
           </div> */}
           <Slider range marks={marks} defaultValue={[10,100]} min={0.1} max={200}  onChangeComplete={minMaxPriceHandler} />
           {/* <div>
             <label>Max price</label>
             <Input type='number' min={minPrice} value={maxPrice}  onChange={(e) => {setMaxPrice(e.target.value)}}/>
           </div> */}
        </div>
        <div>
          <div><b>Brands</b></div>
          <div className={styles.price_range_filter_container}>
           <div style={{display:'flex',flexDirection:'row',gap:'5px',alignItems:'center'}}>
             <label>Aachi</label>
             <Checkbox/>
           </div>
           <div style={{display:'flex',flexDirection:'row',gap:'5px',alignItems:'center'}}>
             <label>Sakthi</label>
             <Checkbox/>
           </div>
          </div>
          <Button className={styles.filter_button} onClick={() => {filteredArray.length === 0 ? filterData() : clearData()}}>{filteredArray.length > 0 ? 'Clear Filter' : 'Filter'}</Button>
        </div>
      </div>}
      {categoryListLoader ? <Spin 
    indicator={<LoadingOutlined />}
   style={{marginLeft: '30%',marginTop:'10%'}}
   size='large'
    spinning/> :<div className={styles.product_grid_for_mobile}>
        <ProductListing data={filterToggle ? filteredPaginationData : paginationData}/>
        <Pagination defaultPageSize={12} onChange={pageHandler} total={productList.length} style={{position:'absolute',right:'20px',bottom:'-35px'}} current={page} pageSizeOptions={pageSizeOptions}/>
      </div>}
    </div>}
   </div>
  )
}

export default CategoriesPage