import Search from 'antd/es/input/Search'
import AccountImage from '../../assets/images/Vector_account.png';
import {AlignLeftOutlined, AlignRightOutlined, CloseOutlined, EnvironmentOutlined, HeartOutlined, MenuOutlined, SearchOutlined, ShoppingCartOutlined, UserOutlined} from '@ant-design/icons';
import styles from './header.module.scss';
import "antd/dist/reset.css";
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CartContext, WishListContext } from '../../config/context/CartContext';
import { getUserDetails } from '../../config/axios/userApis';
import axios from 'axios';
import { getListofProducts, getProductCategories } from '../../config/axios/productApis';
import { Dropdown, Input, Menu, MenuProps, Modal } from 'antd';
import { getShipmentFee } from '../../config/axios/orderApis';
import Logo from '../../assets/images/image-removebg-preview.png';
import CategoriesDropdown from './CategoriesDropdown';
import HamburgerBar from './HamburgerBar';
import { getDeliveryAvailability } from '../../config/axios/dashboardApis';

const Header = () => {

  const navigate = useNavigate();
  const [activeTab,setActiveTab] = useState<string>('home');
  const [hamburgerMenu,setHamburgerMenu] = useState<boolean>(false);
  const [items,setItems] = useState<MenuProps['items']>([]);
  const [userName, setUserName] = useState<string>('');
  const [deliveryZipCode,setDeliveryZipCode] = useState<string>('');
  const {cartItems,getCartTotal,setShipmentFee,setZipCode} = useContext(CartContext);
  const [mobileSearchPopup,setMobileSearchPopup] = useState<boolean>(false);
  const {wishListItems} = useContext(WishListContext);
  const [searchTerm,setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet,setIsTablet] = useState(false);
  const [deliveryAvailable, setDeliveryAvailable] = useState<boolean | null>(null);
  const [deliveryAvailableMessage, setDeliveryAvailableMessage] = useState<string>('');


   //choose the screen size 
   const handleResize = () => {
    if (window.innerWidth <= 720) {
        setIsMobile(true);
        setIsTablet(false);
    } else if (window.innerWidth <= 1024) {
        setIsTablet(true);
        setIsMobile(false);
    }
    else{
      setIsMobile(false);
      setIsTablet(false);
    }
  }



      // Interceptor setup
axios.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle 401 errors
    if (error.response && error.response.status === 401) {
      window.location.reload();
      console.log("Unauthorized, redirecting...");
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      navigate('/');
    }
    // Always reject the promise for error responses
    return Promise.reject(error);
  }
);

function isValidUKPostcode(postcode:string) {
  // Regular expression to match UK postcode
  const postcodeRegex = /^[A-Z]{1,2}\d{1,2}[A-Z]?\s?\d[A-Z]{2}$/i;
  return postcodeRegex.test(postcode);
}


useEffect(() => {
  if(deliveryAvailable !== null){
      if(deliveryAvailable){
        setDeliveryAvailableMessage('Delivery is available for this location')
      }
      else{
        setDeliveryAvailableMessage('Delivery is not available for this location')
      }
  }
},[deliveryAvailable])


useEffect(() => {

   if(deliveryZipCode !== ''){
      getDeliveryAvailability(deliveryZipCode).then((availability:any) => {
        if(availability.status === 200){
          setDeliveryAvailable(true);
          setZipCode(deliveryZipCode);
        }
        else{
          setDeliveryAvailable(false);
        }
      })
      }

},[deliveryZipCode])


// useEffect(() => {
//   if(zipCode !== '' && isValidUKPostcode(zipCode)){
//     getShipmentFee(localStorage.getItem('userId')!,zipCode,getCartTotal()).then((response:any) => {
//         setShipmentFee(response.data);
//     })
//   } 
// },[zipCode])

  useEffect(() => {
    handleResize();
    if(localStorage.getItem('userId') && localStorage.getItem('userId') !== ''){
      getUserDetails(localStorage.getItem('userId')!).then((userResponse:any) => {
        setUserName(userResponse.data.email)
      })
    }
    getProductCategories().then((categoryResponse:any) => {
         const categoryArray = categoryResponse.data.map((category:any,index:number) => {
          return {
            key:index+1,
            label: category.categoryName,
            iconUrl : category.categoryImage,
            id: category.categoryId,
            onClick: () => {navigate('/categories',{state:category.categoryId})}
          }
         }).filter((category: any) => {
          return category.label !== "MULTIPLE_PACKS" && category.label !== 'New Arrivals' && category.label !== 'Best Sellers'
      })

      categoryArray.pop();
      categoryArray.push({
        key:categoryArray.length,
        label: 'Sale',
        id: '',
        onClick: () => {navigate('/categories',{state:'sale'})}
      });
      setItems(categoryArray);
    })
  },[])

  useEffect(() => {
    if(searchTerm !== ''){
      getListofProducts({limit:100,offset:0,search:searchTerm}).then((productsResponse:any) => {
         setSearchResults(productsResponse.data.products);
      })
    }
    else{
      setSearchResults([]);
    }
  },[searchTerm]);

  const hamburgerOnClickFunction = (option:string,categoryId?:number) => {

    switch (option){
      case 'home':
        navigate('/');setActiveTab('home');setHamburgerMenu(false);
        break;
      case 'shop':
        navigate('/categories',{state:categoryId});setActiveTab('shop');setHamburgerMenu(false);
        break;
      case 'multipack':
        navigate('/multipacks');setActiveTab('multipack');setHamburgerMenu(false);
        break;
      case 'newarrivals':
        navigate('/newarrivals');setActiveTab('newarrivals');setHamburgerMenu(false);
        break;
      case 'about':
        navigate('/aboutUs');setActiveTab('about');setHamburgerMenu(false);
        break;
      case 'blog':
        navigate('/blog');setActiveTab('blog');setHamburgerMenu(false);
        break;
      case 'contact':
        navigate('/contact');setActiveTab('contact');setHamburgerMenu(false)
        break;
    }

  }

  const debouncedSearch = useMemo(() => {
    const delay = 300; // Debounce time in milliseconds
    let timeoutId: any;

    return (term: string) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setSearchTerm(term);
        }, delay);
    };
}, [searchTerm]);

const debouncedZipCodeSearch = useMemo(() => {
  const delay = 1500; // Debounce time in milliseconds
  let timeoutId: any;

  return (term: string) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setDeliveryZipCode(term);
      }, delay);
  };
}, [deliveryZipCode]);

const handleZipCodeChange = (data: any) => {
  const term = data;
  debouncedZipCodeSearch(term);
};

const handleChange = (data: any) => {
  const term = data;
  debouncedSearch(term);
};

 
  return (
    <div className={styles.header_container}>
      <div className={styles.header_first_bar}>
          <div className={styles.title_container}>
                 {!isMobile && <div className={styles.title} onClick={() => navigate('/')}><img src={Logo} height={85} width={250}/></div>}
                  {!isMobile && <div className={styles.header_search_container}>
                {!isMobile && !isTablet && <Search placeholder='What are you looking for?' className={styles.header_search_bar} onChange={(e) => handleChange(e.target.value)}  allowClear/>}
                  {searchResults.length > 0 && <div className={styles.search_results_container}>
                    {searchResults.map((product) => {
                      return <div className={styles.search_product} onClick={() => {navigate(`/product/${product.productId}`);setSearchTerm('');setSearchResults([])}}>
                        <img src={product.productImage} height={50} width={50}/>
                        <div>{product.productName}</div>
                        <div>{`\u00A3`} {product.price}</div>
                      </div>
                    })}
                    </div>}  
                  </div>}
          </div>
          <div className={styles.user_details_container}>
                <div className={styles.account_container} onClick={() => navigate('/user')}>
                  <img src={AccountImage} />
                  <div className={styles.account_text}>{userName !== '' ? `Hey, ${userName}`: "Account, Log In or Sign Up"}</div>
                </div>
                <div className={styles.icon_container}>
                  <div onClick={() => navigate('/cart',{state:{isWishList:true}})} style={{paddingTop:'15px'}}>
                    <HeartOutlined className={styles.heading_icon} />
                    <div className={styles.shopping_badge}>{wishListItems.length}</div>
                  </div>
                  <div style={{paddingTop: '15px'}} onClick={() => navigate('/cart',{state:{isWishList:false}})}>
                    <ShoppingCartOutlined className={styles.heading_icon}/>
                    <div className={styles.shopping_badge}>{cartItems.length}</div>
                  </div>
                </div>
          </div>
          <div className={hamburgerMenu ? styles.hamburger_wrapper: styles.normal_container}>
               {!hamburgerMenu && <div className={styles.hamburger_bar}>
                    {/* <UserOutlined onClick={() => navigate('/user')}/> */}
                    <MenuOutlined onClick={() => {setHamburgerMenu(!hamburgerMenu)}} className={styles.heading_icon}/>
                    <div className={styles.title} onClick={() => navigate('/')}><img src={Logo} height={65} width={150}/></div>
                    <div className={styles.right_side_container}>
                    <SearchOutlined className={styles.heading_icon} onClick={() => {setMobileSearchPopup(true)}}/>

                    <img src={AccountImage} onClick={() => navigate('/user')} height={20} />
                     
                      <div style={{paddingTop: '15px'}} onClick={() => navigate('/cart',{state:{isWishList:false}})}>
                        <ShoppingCartOutlined className={styles.heading_icon}/>
                        <div className={styles.shopping_badge}>{cartItems.length}</div>
                    </div>
                    {/* <div onClick={() => navigate('/cart',{state:{isWishList:true}})} style={{paddingTop:'15px'}}>
                        <HeartOutlined className={styles.heading_icon} />
                        <div className={styles.shopping_badge}>{wishListItems.length}</div>
                      </div> */}
                    </div>

              </div>}
              {/* <div className={!hamburgerMenu ? styles.hamburger_list : styles.hamburger_list_active}>
                <div className={styles.hamburger_header}>
                  <CloseOutlined onClick={() => setHamburgerMenu(false)}/>
                  <div className={styles.account_text} onClick={() => navigate('/user')}>{userName !== '' ? `Hey, ${userName}`: "Account, Log In or Sign Up"}</div>
                </div>

                <div className={activeTab === 'home' ? styles.active_tab : ''} onClick={() => {navigate('/');setActiveTab('home');setHamburgerMenu(false)}} >Home</div>
                <div className={activeTab === 'shop' ? styles.active_tab : ''} onClick={() => {navigate('/categories');setActiveTab('shop');setHamburgerMenu(false)}}>Shop</div>
                <div className={activeTab === 'multipack' ? styles.active_tab : ''} onClick={() => {navigate('/multipacks');setActiveTab('multipack');setHamburgerMenu(false)}}>Multipacks</div> 
                <div className={activeTab === 'newarrivals' ? styles.active_tab : ''} onClick={() => {navigate('/newarrivals');setActiveTab('newarrivals');setHamburgerMenu(false)}}>New Arrivals</div>
                <div className={activeTab === 'about' ? styles.active_tab : ''} onClick={() => {navigate('/aboutUs');setActiveTab('about');setHamburgerMenu(false)}}>About Us</div>
                <div className={activeTab === 'blog' ? styles.active_tab : ''} onClick={() => {navigate('/blog');setActiveTab('blog');setHamburgerMenu(false)}}>Blog</div>
                <div className={activeTab === 'contact' ? styles.active_tab : ''} onClick={() => {navigate('/contact');setActiveTab('contact');setHamburgerMenu(false)}}>Contact Us</div>
              </div> */}
              <HamburgerBar hamburgerMenu={hamburgerMenu} onCloseFunction={() => setHamburgerMenu(false)} onOptionClickFunction={(option:string) => {hamburgerOnClickFunction(option)}} activeTab={activeTab} categoriesList={items}/>
          </div>
      </div>
      {isTablet &&  <Search placeholder='What are you looking for?' className={styles.header_search_bar} onChange={(e) => handleChange(e.target.value)}  allowClear/>}
      {!isMobile && !isTablet && <div className={styles.header_sub_container}>
        <div className={styles.header_tab_container}>
        <div className={activeTab === 'home' ? styles.active_tab : ''} onClick={() => {navigate('/');setActiveTab('home')}} >Home</div>
        {/* <div className={activeTab === 'shop' ? styles.active_tab : ''} onClick={() => {navigate('/categories');setActiveTab('shop')}}>Shop</div> */}
         <CategoriesDropdown items={items} />
        <div className={activeTab === 'multipack' ? styles.active_tab : ''} onClick={() => {navigate('/multipacks');setActiveTab('multipack')}}>Multipacks</div>
        <div className={activeTab === 'newarrivals' ? styles.active_tab : ''} onClick={() => {navigate('/newarrivals');setActiveTab('newarrivals')}}>New Arrivals</div>
        <div className={activeTab === 'about' ? styles.active_tab : ''} onClick={() => {navigate('/aboutUs');setActiveTab('about')}}>About Us</div>
        <div className={activeTab === 'blog' ? styles.active_tab : ''} onClick={() => {navigate('/blog');setActiveTab('blog')}}>Blog</div>
        <div className={activeTab === 'contact' ? styles.active_tab : ''} onClick={() => {navigate('/contact');setActiveTab('contact')}}>Contact Us</div>
        </div>
       {!isMobile && !isTablet && <div className={styles.zip_code_container}>
        <div className={styles.deliver_to_container}>
            <EnvironmentOutlined />
            <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>Deliver to <span><Input placeholder='Enter ZIP Code' onChange={(e) => {handleZipCodeChange(e.target.value)}} style={{color:'black'}}/></span></div>
        </div>
        {/* {!localStorage.getItem('userId') && <div className={styles.login_prompt}>Login First to find out delivery charge for your zipcode</div>} */}
        {deliveryAvailableMessage !== '' && deliveryZipCode !== '' && <div className={`${styles.login_prompt} ${deliveryAvailable ? styles.success_prompt : styles.error_prompt}`}>{deliveryAvailableMessage}</div>}
        </div>}
      </div>}
      <div>

      </div>
      {isMobile &&  <Modal onCancel={() => setMobileSearchPopup(false)} onClose={() => setMobileSearchPopup(false)} open={mobileSearchPopup} closeIcon={false} footer={false}>
                <div className={styles.search_modal_container}>
                    <Search placeholder='What are you looking for?'onChange={(e) => handleChange(e.target.value)}  allowClear/>
                      {searchResults.length > 0 && <div className={styles.search_results_container}>
                        {searchResults.map((product) => {
                          return <div className={styles.search_product} onClick={() => {navigate(`/product/${product.productId}`);setSearchTerm('');setSearchResults([]);setMobileSearchPopup(false)}}>
                            <img src={product.productImage} height={50} width={50}/>
                            <div>{product.productName}</div>
                            <div>{`\u00A3`} {product.price}</div>
                          </div>
                        })}
                    </div>}  
                </div>
                  </Modal>
        }
    </div>
  )
}

{/* <><div className={styles.deliver_to_container}>
            <EnvironmentOutlined />
            <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>Deliver to <span><Input placeholder='Enter ZIP Code' value={zipCode}  disabled={!localStorage.getItem('userId')} style={{color:'white'}}/></span></div>
        </div>  {!localStorage.getItem('userId') && <div className={styles.login_prompt}>Login First to find out delivery charge for your zipcode</div>}</> */}

export default Header