import { Button, Input } from 'antd'
import styles from './login.module.scss'
import { useState } from 'react';
import VerifyEmailAddressPage from './VerifyEmailAddressPage';
import ResetPassword from './ResetPassword';
import { forgotPasswordApi } from '../../config/axios/userApis';
import { toast } from '../../components/Toaster/ToasterComponent';

const ForgotPassword = () => {
  const [resetPasswordPage, setResetPasswordPage] = useState<boolean>(false);
  const [verifyEmailPage, setVerifyEmailPage] = useState<boolean>(false);
  const [verificationEmail,setVerificationEmail] = useState<string>('');

  const verificationCodeHandler = (code: string) => {
      console.log('Code',code)
      setVerifyEmailPage(false);
      setResetPasswordPage(true);
  }

  const forgotPasswordHandler = () => {
    forgotPasswordApi(verificationEmail).then((response) => {
      if(response.status === 400){
         toast.error("Invalid email", {
                    position: "top-right",
                    });
      }
      else if(response.status === 200){
      toast.success('A Link has been sent to your email for reset password', {
        position: "top-right",
        })
      }
    })
  }

  const resetPasswordHandler = (password: string) => {
      console.log('Password',password)
      setResetPasswordPage(false);
      //send password to server
  }

  // if(verifyEmailPage){
  //   return <VerifyEmailAddressPage verifyHandler={(code:string) => verificationCodeHandler(code)}/>
  // }
  if(resetPasswordPage){
    return <ResetPassword resetPasswordHandler={(password:string) => resetPasswordHandler(password)}/>
  }
  return (
    <div className={styles.login_container}>
        <div className={styles.forgot_password_heading_container}>
            <div className={styles.login_title}>Forget Password</div>
            <div>Enter the email address or mobile phone number associated with your ARR Fine Foods</div>
        </div>
        <div className={styles.forgot_password_field}>
            <div className={styles.forgot_password_label}>Email</div>
            <Input type='email' value={verificationEmail} onChange={(e) => setVerificationEmail(e.target.value)}/>
        </div>
        <Button type='primary' onClick={() => {forgotPasswordHandler()}}>Send Code</Button>

    </div>
  )
}

export default ForgotPassword