import React, { useState } from 'react';
import styles from './categoryCarousal.module.scss'; // Import the modular SCSS
import { ProductCarousalProps } from '../ProductCarousal/ProductCarousal';
import { chunkArray } from '../../utils/chunkArray';

type CategoryCarousalProps = ProductCarousalProps & CategoryCarousalComponentProps

interface CategoryCarousalComponentProps {
    callbackFunction : (id:any) => void
}

const CategoryCarousalComponent = ({productData,countPerRow,callbackFunction}: CategoryCarousalProps) => {

    const [currentIndex,setCurrentIndex] = useState<number>(0);

    const chunks = chunkArray(productData, countPerRow);

    const prevPage = () => {
        if(currentIndex > 0){
           setCurrentIndex(currentIndex - 1)
        }
    }

    const nextPage = () => {
        if(currentIndex < chunks.length - 1){
            setCurrentIndex(currentIndex + 1)
        }
    }

   return <>
   <div className={styles.carousal}>
        <button onClick={prevPage} disabled={currentIndex === 0}>
                    &#10094;
        </button>
   
    <div className={styles.carousal_container}>
        {
            chunks[currentIndex]?.map((item:any,index:number) => (
                <div className={styles.carousal_item_container} onClick={() => callbackFunction(item.id)}>
                        <div className={styles.background_circle_carousal}>
                            <img src={item.iconUrl} alt={item.name} className={styles.background_image_carousal} />
                        </div>
                        <div>{item.name}</div>
                    </div>
            ))
        }
    </div>

    <button onClick={nextPage} disabled={currentIndex > chunks.length - 1}>
                    &#10095;
    </button>

   </div>
   </>
};

export default CategoryCarousalComponent;
