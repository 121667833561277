import axios from "axios"

export const getBlogs = async () => {
    try{
      return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/blog/all`)
    }catch(e){
        return e
    }
}

export const getBlogEntry = async (blogId:string) => {
    try{
        return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/blog/${blogId}`)
      }catch(e){
          return e
      }
}