import { Select, Spin } from 'antd'
import Search from 'antd/es/input/Search';
import styles from './blogPage.module.scss';
import { useEffect, useState } from 'react';
import BlogCard from '../../components/BlogCard/BlogCard';
import { getBlogs } from '../../config/axios/blogApis';

export interface BlogType {
    id: number;
    imageUrl: string;
    title: string;
    description: string;
}

const BlogPage = () => {

    const [filterValue, setFilterValue] = useState<string>('Relevance');
    const [blogList,setBlogList] = useState<BlogType[]>([]);
    const [pageLoader, setPageLoader] = useState<boolean>(false);

    const blogOptions = [
        {
            id: '1',
            name: 'Relevance',
            value: 'Relevance'
        },{
            id:'2',
            name:'Popularity',
            value: 'Popularity'
        },{
            id: '3',
            name: 'Low to High',
            value: 'Low to High'
        },{
            id: '4',
            name: 'High to Low',
            value: 'High to Low'
        },{
            id: '5',
            name: 'Best Seller',
            value: 'Best Seller'
        },{
            id: '6',
            name: 'Newest',
            value: 'Newest'
        }
    ]

    useEffect(() => {
        setPageLoader(true);
        getBlogs().then((response:any) => {
            const resultArray = response.data.map((entry:any) => {
                return {
                     id: entry.blogId,
                     imageUrl: entry.blogImageUrl,
                     title: entry.blogTitle,
                     description: entry.blogDescription
                }
            })
            setBlogList(resultArray);
            setPageLoader(false);
        })
    },[])

    if(pageLoader){
        return <div className={styles.loader_container}>
     
        <Spin tip="Loading" size='large'></Spin>
      
      </div>
    }

  return (
    <div className={styles.blog_page_wrapper}>
        <div className={styles.blog_page_heading}>
            <h3>Blogs | Insights</h3>
            {/* <div>Blogs for daily views</div> */}
        </div>
        {/* <div className={styles.search_filter_container}>
            <Search placeholder='What are you looking for ?'/>
            <div>
                <span>Sort by:</span>
                <Select options={blogOptions} value={filterValue} />
            </div>
        </div> */}
        <div className={styles.blog_card_grid}>
            {
                blogList.length > 0 && blogList.map((blog) => <BlogCard blogData={blog} />)
            }

        </div>

    </div>
  )
}

export default BlogPage