import axios from "axios"

export const getAllSale = async () => {
  try{
    return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/sales/all`)
  }catch(err){
    return err;
}
}

export const getSalebyId = async (saleId:number) => {
    try{
        return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/sales/${saleId}`)
      }catch(err){
        return err;
    }
}