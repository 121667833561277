import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import styles from './billSummary.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import Input from 'antd/es/input/Input';
import { CartContext } from '../../config/context/CartContext';
import { validatePromo } from '../../config/axios/promoApis';

interface BillSummaryProps{
  isButtonAvailable: boolean;
  billDetails: any;
}

interface PromoCodeStatus {
  status:boolean | null,
  message: string
}

const BillSummary = ({isButtonAvailable,billDetails}:BillSummaryProps) => {
  const navigate = useNavigate();
  const {totalPrice,totalQuantity,shipmentFee} = billDetails
  const {cartItems} = useContext(CartContext);
  const [promoCode,setPromoCode] = useState<string>('');
  const [promoCodeStatus, setPromoCodeStatus] = useState<PromoCodeStatus>({
    status:null,
    message: ''
  });

 const updatedShipmentFee = useRef<any>(shipmentFee);

 const checkPromoCodeStatus = () => {
  const productIdArray = cartItems.map((item) => item.id);
  validatePromo(promoCode,productIdArray).then((response:any) => {
    console.log('Promo Validity Response',response.response.data);
    if(response.status !== 200){
      setPromoCodeStatus({status:false,message: response.response.data.description})
    }
    else{
      setPromoCodeStatus({status:true,message:`Promocode ${promoCode} applied successfully`})
    }
 
  })

 }

  useEffect(() => {
    if(totalPrice < 35){
         updatedShipmentFee.current = (shipmentFee + 5)
    }

  },[billDetails])

  return (
    <div className={styles.bill_summary_container}>
      <div className={styles.product_summary_title}>Product Summary</div>
      <div className={styles.subitems_list_container}>
        <div className={styles.subitems_container}>
          <div><b>Subtotal</b> ({totalQuantity} { totalQuantity > 1 ? `items` : 'item' })</div>
          <div><b>{`\u00A3`} {totalPrice.toFixed(2)}</b></div>
        </div>
        <div className={styles.subitems_container}>
          <div><b>Shipping Charges</b></div>
          <div><b>{`\u00A3`} {updatedShipmentFee.current}</b></div>
        </div>
      </div>
      <div className={styles.divider_light}/>
      <div className={styles.price_container}>
        <div className={styles.estimated_total_container}>
          <div><b>Estimated Total</b></div>
          <div><b>{`\u00A3`} {(+totalPrice + +updatedShipmentFee.current).toFixed(2)}</b></div>
        </div>
        <div className={styles.tax_prompt}>Applicable taxes will be calculated at checkout.</div>
        <div className={styles.promo_code_container}>
          <div>Have a promo code ?</div>
          <div>Code will work on checkout page</div>
        </div>
        <div className={styles.apply_code}><Input value={promoCode} onChange={(e) => {setPromoCode(e.target.value);setPromoCodeStatus((status) => ({...status,message:''}))}} variant='filled' addonAfter={<div className={styles.apply_code_text} onClick={() => {checkPromoCodeStatus()}}>Apply Code</div>}/></div>
        {
         promoCode !== '' && !promoCodeStatus.status && promoCodeStatus.message !== '' && <div className={styles.promo_code_error_text}>{promoCodeStatus.message}</div>
        }
        <div className={styles.divider_bold}/>
        <div className={styles.total_container}>
          <div><b>Total</b></div>
          <div><b>{`\u00A3`} {(+totalPrice + +updatedShipmentFee.current).toFixed(2)}</b></div>
        </div>
      </div>
      {isButtonAvailable && <Button text='Continue to checkout' type='primary' onClick={() => {navigate('/billing')}} className={styles.checkout_button}/>}
    </div>
  )
}

export default BillSummary