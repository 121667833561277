import React, { useContext, useEffect, useState } from 'react'
import styles from './billing.module.scss';
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Modal, Radio, RadioChangeEvent, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Button from '../../components/Button/Button';
import { redirectToCheckout } from '../../config/axios/productApis';
import { CartContext } from '../../config/context/CartContext';
import { getAllAddressforUser, getUserDetails } from '../../config/axios/userApis';
import cn from 'classnames';
import { getShipmentFee } from '../../config/axios/orderApis';
import { toast } from '../../components/Toaster/ToasterComponent';

interface ShipmentDetailsProps{
  first_name: string,
  last_name: string,
  street_address: string,
  apartment_number: string,
  city: string,
  state: string,
  zipCode: string,
  phone_number:string,
  delivery_details: string,
  additional_instructions?: string
}

type ShipmentDetailsComponentProps = {
  shipmentFeeHandler : (data:number) => void
}

type DeliveryDetailsProps = {
  email:string,
  phoneNumber:string,
}


const ShipmentDetails = ({shipmentFeeHandler}:ShipmentDetailsComponentProps) => {
  const [formValues,setFormValues] = useState<ShipmentDetailsProps>({
    first_name: '',
    last_name: '',
    street_address: '',
    apartment_number: '',
    city: '',
    state: '',
    zipCode: '',
    phone_number: '',
    delivery_details: ''
  });

  const [zipCode,setZipCode] = useState<string>('');

  const {cartItems,getCartTotal,setPlacedOrderId,getTotalPaymentAmount,setDeliveryAddressId,deliveryAddressId,shipmentFee} = useContext(CartContext);
  const [selectAddressModal,setSelectAddressModal] = useState<boolean>(false);
  const [selectedAddressList, setSelectedAddressList] = useState<any>([]);
  const [selectedAddress,setSelectedAddress] = useState<any>({});
  const [deliveryDetails , setDeliveryDetails] = useState<DeliveryDetailsProps>({
    email: '',
    phoneNumber: ''
  });
  const [submitButtonLoader, setSubmitButtonLoader] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getUserDetails(localStorage.getItem('userId')!).then((userResponse) => {
      console.log('User Response',userResponse)
      if(userResponse.data){
        const userResponseData = userResponse.data;
        setDeliveryDetails({
          email : userResponseData.email,
          phoneNumber: userResponseData.userDetails.phNumber
        })
      }
    })
    getAllAddressforUser(localStorage.getItem('userId')!).then((addressResponse) => {
      const addressArray = addressResponse.data.map((addressObject:any) => {
        if(Array.isArray(addressObject.address)){
        return {...addressObject.address[0],addressId:addressObject.addId};
        }
        else{
          return {...addressObject.address,addressId:addressObject.addId};
        }
      })
      if(addressArray.length > 1){
              setSelectedAddressList(addressArray);
              setSelectAddressModal(true);
              
          }
      else if (addressArray.length === 1){
            setSelectedAddress(addressArray[0]);
      }
      setSelectedAddressList(addressArray);
    })
  },[])

  function isValidUKPostcode(postcode:string) {
    // Regular expression to match UK postcode
    const postcodeRegex = /^[A-Z]{1,2}\d{1,2}[A-Z]?\s?\d[A-Z]{2}$/i;

    return postcodeRegex.test(postcode);
}

function isValidUKPhoneNumber(phoneNumber:string) { 

  const phoneNumberRegex = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;

  return phoneNumberRegex.test(phoneNumber)
}

  useEffect(() => {
    if(zipCode !== '' && isValidUKPostcode(zipCode)){
      getShipmentFee(localStorage.getItem('userId')!,form.getFieldValue('zipCode'),getCartTotal()).then((response:any) => {
        shipmentFeeHandler(response.data.toString());
      })
    } 
  },[zipCode])


  useEffect(() => {
    form.setFieldsValue({
      first_name: selectedAddress?.firstName,
      last_name: selectedAddress?.secondName,
      street_address: selectedAddress?.streetAddress,
      apartment_number: selectedAddress?.apt_flat_name,
      city: selectedAddress?.city,
      state: selectedAddress?.state,
      zipCode: selectedAddress?.zipCode,
      phone_number: selectedAddress?.phNumber,
      delivery_details: ''
    })
    setZipCode(selectedAddress?.zipCode);
  },[selectedAddress])

  const disableButtonCondition = deliveryDetails.email === '' || deliveryDetails.phoneNumber === ''

  const onFinish = async (values:any) => {
    setSubmitButtonLoader(true);
    try {
      const values = await form.validateFields();
      console.log('All fields are filled:', values);
    } catch (errorInfo:any) {
      console.log('Fields with errors or empty:', errorInfo.errorFields);
      setSubmitButtonLoader(false);
      return;
    }
    console.log('Success:', values);
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
    if(!isValidUKPhoneNumber(deliveryDetails.phoneNumber)){
      toast.error("The Delivery phone number must be a valid UK phone number", {
        position: "top-right",
        })
        setSubmitButtonLoader(false);
      return;
    }
    if(!emailRegex.test(deliveryDetails.email)){
      toast.error("The Delivery email must be a valid email address", {
        position: "top-right",
        })
        setSubmitButtonLoader(false);
      return;
    }
    if(localStorage.getItem('token') !== null){
      localStorage.setItem('addressId',deliveryAddressId)
    redirectToCheckout(localStorage.getItem('userId')!,localStorage.getItem('token')!.slice(1,-1),
    {'productList':cartItems.map((item) => {return {
      productId: item.id,
      productName: item.title,
      quantity: item.quantity,
      productImage:item.imageUrl
    }}),'successUrl':`https://arrfinefoods.com/success`,'cancelUrl':`https://arrfinefoods.com/cart`,'totalamount':getTotalPaymentAmount() * 105}).then((response:any) => {
      setSubmitButtonLoader(false);
      if(response.status === 200) {
        console.log("Rezzponse",response.data)
        setPlacedOrderId(response.data.OrderId);
        localStorage.setItem('placedOrderId', response.data.OrderId);
        window.location.href = response.data.CheckoutUrl;
      }
      
    })
  }
  };

  return (
   <>
     
      <div className={styles.pickup_header_desc}>Where should we deliver your order?</div>
      <Form className={styles.shipment_form_container} onFinish={onFinish} form={form}>
        <div className={styles.form_fields_container}>
          <div>
            <div className={styles.form_field_label}>First Name</div>
            <Form.Item name="first_name" rules={[{ required: true, message: 'Please input your Firstname!' }]}>
              <Input onChange={(e) => setFormValues((prev) => ({...prev,first_name:e.target.value}))}/>
            </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>Last Name</div>
          <Form.Item name="last_name" rules={[{ required: true, message: 'Please input your lastname!' }]}>
            <Input onChange={(e) => setFormValues((prev) => ({...prev,last_name:e.target.value}))}/>
          </Form.Item>
          </div>
        </div>
        <div className={styles.form_fields_container}>
        <div>
           <div className={styles.form_field_label}>Street Address</div>
          <Form.Item name="street_address" rules={[{ required: true, message: 'Please input your street address!' }]}>
            <Input  onChange={(e) => setFormValues((prev) => ({...prev,street_address:e.target.value}))}/>
          </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>Apt, flat, etc (optional)</div>
          <Form.Item name="apartment_number">
            <Input  onChange={(e) => setFormValues((prev) => ({...prev,apartment_number:e.target.value}))}/>
          </Form.Item>
          </div>
        </div>
        <div className={styles.form_fields_container}>
        <div>
           <div className={styles.form_field_label}>City</div>
          <Form.Item name="city" rules={[{ required: true, message: 'Please input your city!' }]}>
            <Input  onChange={(e) => setFormValues((prev) => ({...prev,city:e.target.value}))}/>
          </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>State</div>
          <Form.Item name="state" rules={[{ required: true, message: 'Please input your state!' }]}>
            <Input  onChange={(e) => setFormValues((prev) => ({...prev,state:e.target.value}))}/>
          </Form.Item>
          </div>
        </div>
        <div className={styles.form_fields_container}>
          <div>
           <div className={styles.form_field_label}>Zip code</div>
            <Form.Item name="zipCode" rules={[{ required: true, message: 'Please input your zipcode!' }]}>
              <Input  onChange={(e) => {setFormValues((prev) => ({...prev,zipCode:e.target.value}));setZipCode(e.target.value)}}/>
            </Form.Item>
          </div>
          <div>
           <div className={styles.form_field_label}>Phone Number</div>
          <Form.Item name="phone_number" rules={[{ required: true, message: 'Please input your phone number!'},{pattern: /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/,message:'The number should be in UK phone pattern'}]}>
            <Input  onChange={(e) => setFormValues((prev) => ({...prev,phone_number:e.target.value}))}/>
          </Form.Item>
          </div>
        </div>
        {/* <div className={styles.pickup_header_desc}>Delivery Instructions(optional)</div> */}
        {/* <Form.Item name="delivery_details">
        <div className={styles.radio_group}>
            <label className={styles.radio_button}>
              <input 
                type="radio" 
                name="delivery_details" 
                value="leave" 
                checked={formValues.delivery_details === "leave"} 
                onChange={(e) => setFormValues((prev) => ({ ...prev, delivery_details: e.target.value }))}
              />
              <span className={styles.label_text}>Leave at the door</span>
            </label>
            <label className={styles.radio_button}>
              <input 
                type="radio" 
                name="delivery_details" 
                value="hand" 
                checked={formValues.delivery_details === "hand"} 
                onChange={(e) => setFormValues((prev) => ({ ...prev, delivery_details: e.target.value }))}
              />
              Hand it to me
            </label>
        </div>
        </Form.Item> */}
        <Form.Item>
           <TextArea placeholder='Additional instructions (optional)'/>
        </Form.Item>
        <div className={styles.form_fields_container}>
        <div>
           <div className={cn(styles.form_field_label,styles.mandatory_field)}>Phone Number</div>
              <Form.Item rules={[{ required: true, message: 'Please input your phone number!'},{pattern: /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/,message:'The number should be in UK phone pattern'}]}>
                <Input value={deliveryDetails.phoneNumber} onChange={(e) => {setDeliveryDetails((prev) => ({...prev,phoneNumber:e.target.value}))}}/>
              </Form.Item>
        </div>
        <div>
           <div className={cn(styles.form_field_label,styles.mandatory_field)}>Email Address</div>
            <Form.Item rules={[{pattern: /^[^@]+@[^@]+\.[^@]+$/,message:'Please check the pattern of email address'},{required:true,message:'Please Input Email Address'}]}>
              <Input value={deliveryDetails.email} onChange={(e) => {setDeliveryDetails((prev) => ({...prev,email:e.target.value}))}}/>
            </Form.Item>
        </div>
        </div>
        <div className={cn(styles.form_fields_container,styles.form_button_container)}>
          {/* <div className={styles.form_checkbox_container}>
            <Checkbox />
            <div>
              <div>Text me the order status updates</div>
              <div>For message, rate  may be apply</div>
            </div>
          </div> */}
         <button type='submit' className={cn(styles.form_submit_button,{
          [styles.disabled]: disableButtonCondition
         })} disabled={disableButtonCondition}>{submitButtonLoader ? <Spin 
          indicator={<LoadingOutlined />}
          style={{ fontSize: 10,color:'white' }}
          spinning/> :'Continue to Payment'}</button>
        </div>
      </Form>
      <Modal open={selectAddressModal} onClose={() => {setSelectAddressModal(false)}} onCancel={() => {setSelectAddressModal(false)}} footer={false}>
        <div>
           <h3>Select Address</h3>
           <div className={styles.address_list_container}>
            {selectedAddressList.map((address:any) => {
              if(Array.isArray(address)){
                return (<div className={styles.address_list_item} onClick={() => {setSelectedAddress(address[0]);setSelectAddressModal(false);setDeliveryAddressId(address[0].addressId)}}>
                      <div className={styles.address_name}>{address[0].firstName} {address[0].lastName}</div>
                      <div>{address[0].aptFlatName}, {address[0].streetAddress} {address[0].zipCode}, United Kingdom</div>
                      <div className={styles.footer_container}>
                      <div className={styles.contact_details}>
                        <div>Phone Number:</div>
                        <div>{address[0].phNumber}</div>
                      </div>
                    </div>
                    </div>)
              }
              else{
                return (<div className={styles.address_list_item} onClick={() => {setSelectedAddress(address);setSelectAddressModal(false);setDeliveryAddressId(address.addressId)}}>
                  <div className={styles.address_name}>{address.firstName} {address.lastName}</div>
                  <div>{address.aptFlatName}, {address.streetAddress} {address.zipCode}, United Kingdom</div>
                  <div className={styles.footer_container}>
                  <div className={styles.contact_details}>
                    <div>Phone Number:</div>
                    <div>{address.phNumber}</div>
                  </div>
                </div>
                </div>)
              }
             
            })}
           </div>
        </div>
      </Modal>
    </>
  )
}

export default ShipmentDetails