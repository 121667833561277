// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogCard_blog_tag__2pkP8 {
  background-color: #EAFCE6;
  color: #0C9A00;
  height: 18px;
  width: 45px;
  font-size: 8px;
  font-weight: 500;
  padding: 3px;
}

.blogCard_card_content__SlCIS {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blogCard_blog_card_cover_image__DeedN {
  width: 100%;
}

@media (max-width: 768px) {
  .blogCard_blog_card__Qi-NW {
    height: 275px;
  }
  .blogCard_blog_title__T\\+Wqn {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/BlogCard/blogCard.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI;IACI,aAAA;EACN;EAEE;IACI,4BAAA;IACA,oBAAA;IACA,qBAAA;IACA,gBAAA;IACA,uBAAA;IACA,mBAAA;EAAN;AACF","sourcesContent":[".blog_tag{\r\n    background-color: #EAFCE6;\r\n    color: #0C9A00;\r\n    height: 18px;\r\n    width: 45px;\r\n    font-size: 8px;\r\n    font-weight: 500;\r\n    padding: 3px;\r\n}\r\n\r\n.card_content{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n}\r\n\r\n.blog_card_cover_image{\r\n    width: 100%;\r\n}\r\n\r\n@media (max-width:768px) {\r\n    .blog_card{\r\n        height: 275px;\r\n    }\r\n\r\n    .blog_title{\r\n        -webkit-box-orient: vertical;\r\n        display: -webkit-box;\r\n        -webkit-line-clamp: 2;\r\n        overflow: hidden;\r\n        text-overflow: ellipsis;\r\n        white-space: normal;\r\n    }\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blog_tag": `blogCard_blog_tag__2pkP8`,
	"card_content": `blogCard_card_content__SlCIS`,
	"blog_card_cover_image": `blogCard_blog_card_cover_image__DeedN`,
	"blog_card": `blogCard_blog_card__Qi-NW`,
	"blog_title": `blogCard_blog_title__T+Wqn`
};
export default ___CSS_LOADER_EXPORT___;
