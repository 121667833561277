import Button from '../../components/Button/Button'
import CategoryCarousal from './CategoryCarousal'
import styles from './homepage.module.scss'
import TeaBox from '../../assets/images/image 48_taj_mahal.png'
import ProductListing from '../ProductListing/ProductListing'
import NewArrivals from './NewArrivals'
import BrandsList from '../../assets/images/Group 39513_brands_list.png';
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useMemo, useState } from 'react'
import { getListofProducts, getProductById, getProductCategoryById, getProductsInCategory } from '../../config/axios/productApis'
import Testimonials from './Testimonials'
import MobileAppLaunch from './MobileAppLaunch'
import cn from 'classnames';
import { getDeliveryAvailability, getImageForDashboard } from '../../config/axios/dashboardApis'
import { CartContext, WishListContext } from '../../config/context/CartContext'
import { Card, Input, Modal, Spin } from 'antd'
import { EnvironmentOutlined, HeartFilled } from '@ant-design/icons'
import ProductCarousal from '../../components/ProductCarousal/ProductCarousal'
import SliderComponent from '../../components/SliderComponent/SliderComponent'
import { getAllSale } from '../../config/axios/saleApis'
import ProductCard from '../../components/ProductCard/ProductCard'

export interface ProductListProps{
  imageUrl: string,
  title: string,
  description: string,
  price: string | number,
  id: string,
}

type ImageData = {
 imageId: number,
 imageUrl: string,
 redirectionUrl: string
}

export type SaleDetails = {
  saleId:number,
  saleName: string,
  discount: number,
  productList: ProductListProps[]
}

const HomePage = () => {

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet,setIsTablet] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [productList, setProductList] = useState<ProductListProps[]>([{
    imageUrl: '',
    title: '',
    description: '',
    price: 0,
    id: '',
  }])

  const [zipCodePopup, setZipCodePopup] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [deliveryZipCode, setDeiveryZipCode] = useState<string>('');
  const [deliveryavailable, setDeliveryAvailable] = useState<boolean | null>(null);
  const [dashboardImages, setDashboardImages] = useState<ImageData[]>([]);
  const [newArrivalsImages,setNewArrivalsImages] = useState<ImageData[]>([]);
  const [heroImagesArray,setHeroImagesArray] = useState<ImageData[]>([]);
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [currentPage,setCurrentPage] = useState<number>(0);
  const [topPicksImageArray, setTopPicksImageArray] = useState<ImageData[]>([]);
  const [multipackList,setMultipackList] = useState<ProductListProps[]>([]);
  const [veggieList,setVeggieList] = useState<ProductListProps[]>([]);
  const [meatList,setMeatList] = useState<ProductListProps[]>([]);



  const topPicksImagesIds = [37,38,39,40,41];
  const multiPackListArray = [1533, 899, 1527, 1537, 1042, 954, 1046, 1047, 756, 1518, 1548, 1028, 1333, 901, 1529, 963, 1539, 1513, 1531, 897, 1100, 762, 893, 1546, 1530, 1542, 1544, 1357, 1516, 1260, 969, 1520, 1517, 1098, 1528, 1534, 1044, 955, 1052, 1332, 1331, 1057, 949, 887, 964, 1056, 1545, 1547, 1029, 888, 1541, 760, 958, 895, 891, 1049, 1549, 1039, 959, 1524, 1068, 1045, 1050, 950, 1522, 1525, 1538, 890, 966, 962, 968, 744, 956, 1523, 1521, 764, 1069, 1536, 967, 1367, 1514, 1060, 1519, 1532, 881, 1515, 1043, 1061, 1535, 1540, 961, 1067, 894, 1543, 1334];
  const veggieListArray = [1161, 1167, 1193, 1146, 1229, 1215, 1226, 1157, 1182, 1200, 1224, 1223, 1148, 1197, 1155, 1190, 1218, 1202, 1189, 1179, 1228, 1174, 1176, 1199, 1145, 1219, 1191, 1210, 1166, 1214, 1172, 1143, 1194, 1220, 1195, 1170, 1208, 1187, 1160, 1149, 1212, 1183, 1211, 1162, 1169, 1163, 1196, 1152, 1177, 1156, 1207, 1204, 1180, 1173, 1225, 1144, 1188, 1213, 1151, 1164, 1185, 1227, 1171, 1209, 1205, 1222, 1178, 1165, 1201, 1198, 1181, 1203, 1147, 1153, 1158, 1159, 1216, 1184, 1186, 1221, 1206, 1168, 1217, 1192, 1154, 1150, 1175];
  const meatListArray = [763, 752, 754, 748, 765, 751, 753, 757, 755, 759, 758, 749, 746, 745, 747, 750, 761];


    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth <= 720) {
        setIsMobile(true);
        setIsTablet(false);
        setIsWideScreen(false);
    } else if (window.innerWidth <= 1024) {
        setIsTablet(true);
        setIsMobile(false);
        setIsWideScreen(false);
        
    }
    else if (window.innerWidth > 1544){
      setIsMobile(false);
      setIsTablet(false);
      setIsWideScreen(true);
      
    }
    else{
      setIsMobile(false);
      setIsTablet(false);
      setIsWideScreen(false);
      
    }
  }

  const getShuffledArray = (arr: number[], n: number): number[] => {
    const arrCopy: number[] = [...arr]; // Make a shallow copy of the array to avoid mutation

    // Fisher-Yates shuffle
    for (let i = arrCopy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arrCopy[i], arrCopy[j]] = [arrCopy[j], arrCopy[i]]; // Swap elements
    }

    // Return the first 'n' elements of the shuffled array
    return arrCopy.slice(0, n);
};



  const getImagesData = async (imagesArray:number[]) => {
    // const imagesArray = isMobile ? [10,13,16,19] : isTablet ? [22,23,24,25] : [12,15,18,21]
    const imageArray:ImageData[] = []
    for (let i=0 ; i<imagesArray.length;i++){
      const imageResponse: any = await getImageForDashboard(imagesArray[i].toString());
      if(imageResponse && imageResponse.data.imageUrl){
        imageArray.push({imageId: imageResponse.data.imageId,
          imageUrl: imageResponse.data.imageUrl,
          redirectionUrl: imageResponse.data.redirectionUrl

        })
      }
    }
    return imageArray;
  }

  const debouncedSearch = useMemo(() => {
    const delay = 1500; // Debounce time in milliseconds
    let timeoutId: any;

    return (term: string) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setDeiveryZipCode(term);
        }, delay);
    };
}, [deliveryZipCode]);

const handleChange = (data: any) => {
  setDeliveryAvailable(null);
  const term = data;
  debouncedSearch(term);
};




  const {zipCode,setZipCode} = useContext(CartContext)


  useEffect(() => {
    if(deliveryZipCode !== ''){
    getDeliveryAvailability(deliveryZipCode).then((availability:any) => {
      if(availability.status === 200){
        setDeliveryAvailable(true);
        setZipCode(deliveryZipCode);
        setTimeout(() => {
          setZipCodePopup(false);
        },5000)
      }
      else{
        setDeliveryAvailable(false);
      }
    })
    }


  },[deliveryZipCode])

  const multipackHandler = () => {
    const productArray = getShuffledArray(multiPackListArray,10);
    const valueArray: any[] = [];
    Promise.all(
      productArray.map((productId: number) => 
        getProductById(productId.toString()).then((product: any) => {
          const productData = product.data;
          return {
            title: productData.productName,
            description: productData.description,
            price: productData.price,
            id: productData.productId,
            imageUrl: productData.productImage
          };
        })
      )
    ).then((results) => {
      valueArray.push(...results);
      setMultipackList(valueArray);
    }).catch((error) => {
      console.error("Error fetching products:", error);
    });

  }

  const veggieListHandler = () => {
    const productArray = getShuffledArray(veggieListArray,10);
    const valueArray: any[] = [];
    Promise.all(
      productArray.map((productId: number) => 
        getProductById(productId.toString()).then((product: any) => {
          const productData = product.data;
          return {
            title: productData.productName,
            description: productData.description,
            price: productData.price,
            id: productData.productId,
            imageUrl: productData.productImage
          };
        })
      )
    ).then((results) => {
      valueArray.push(...results);
      setVeggieList(valueArray);
    }).catch((error) => {
      console.error("Error fetching products:", error);
    });

  }

  const meatListHandler = () => {
    const productArray = getShuffledArray(meatListArray,10);
    const valueArray: any[] = [];
    Promise.all(
      productArray.map((productId: number) => 
        getProductById(productId.toString()).then((product: any) => {
          const productData = product.data;
          return {
            title: productData.productName,
            description: productData.description,
            price: productData.price,
            id: productData.productId,
            imageUrl: productData.productImage
          };
        })
      )
    ).then((results) => {
      valueArray.push(...results);
      setMeatList(valueArray);
    }).catch((error) => {
      console.error("Error fetching products:", error);
    });

  }


  const getAllImagesData = async () => {
    try {
      const heroImagesArray = window.innerWidth <= 720 ? [12,15,18,21,33,34]  : isTablet ? [22,23,24,25,35,36] : isWideScreen ? [10,13,16,19,35,36] : [11,14,17,20,35,36];
      const newArrivalsData = window.innerWidth <= 720 ? [31,32] : [26,27,28,29,30]
      // Use Promise.all to fetch both sets of data in parallel
      const [heroImagesData, newArrivalsImage,topPicksImages] = await Promise.all([
        getImagesData(heroImagesArray),
        getImagesData(newArrivalsData),
        getImagesData(topPicksImagesIds)
      ]);

      // Update state after both data sets are retrieved
      setDashboardImages(heroImagesData);
      const heroImages = JSON.parse(JSON.stringify(heroImagesData)).slice(0,heroImagesArray.length-2);
      setHeroImagesArray(heroImages);
      setCurrentIndex(0);
      setNewArrivalsImages(newArrivalsImage);
      setTopPicksImageArray(topPicksImages);
      setPageLoader(false); // Hide the loader once data is fetched
    } catch (error) {
      console.error("Error fetching images data:", error);
      // Handle error here if needed, such as setting an error state
    }
  };

  useEffect(() => {
    handleResize();
    setPageLoader(true);
     // Check local storage to determine if the popup should be shown
     const hasShownPopup = localStorage.getItem('popupShown');

     multipackHandler();

     veggieListHandler();

     meatListHandler();
   
    if(zipCode === '' && !hasShownPopup) {
      setZipCodePopup(true);
      localStorage.setItem('popupShown', 'true');
    }
    getProductCategoryById('18').then((productsResponse:any) => {
      const valueArray: any[] = [];
      const productArray = JSON.parse(productsResponse.data.arrayOfProduct);
      Promise.all(
        productArray.map((productId: number) => 
          getProductById(productId.toString()).then((product: any) => {
            const productData = product.data;
            return {
              title: productData.productName,
              description: productData.description,
              price: productData.price,
              id: productData.productId,
              imageUrl: productData.productImage
            };
          })
        )
      ).then((results) => {
        valueArray.push(...results);
        setProductList(valueArray);
      }).catch((error) => {
        console.error("Error fetching products:", error);
        setPageLoader(false);
      });
    })
    getAllImagesData();
  },[isMobile])

  useEffect(() => {
    if (heroImagesArray.length === 0) return;
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex === (heroImagesArray.length - 1 ) ? 0 : prevIndex + 1;
        return nextIndex;
      });
  }, 5000)
  
  return () => clearInterval(intervalId);
  },[heroImagesArray])

  if(dashboardImages.length === 0 || newArrivalsImages.length === 0){
    return (

      <div className={styles.loader_container}>
     
        <Spin tip="Loading" size='large'></Spin>
      
      </div>
     
    )
  }

  const prevPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const generateHeroImage = (item:any) => {
      return <div className={`${styles.veggie_container}`}>
        <img src={item?.imageUrl} alt="Main Image" />
        </div>
            
  }

  const saleProductCard = (item:any,discount?:number) => {
    return <ProductCard item={item} isSale={true} discountPrice={discount} />
  }

  const topPicksItem = (item:any) => {
    return <img src={item?.imageUrl} alt="top picks image" className={styles.top_pick_image} />
  }





  return (
    <div className={styles.homepage_container}>
      {!isMobile ?  <div className={`${styles.veggie_container}`}>
            <img src={heroImagesArray[currentIndex]?.imageUrl} alt="Main Image" />
           {!isMobile && <div className={styles.hero_image_button_container}>
              <button onClick={() => setCurrentIndex(currentIndex - 1)} disabled={currentIndex === 0}>&#10094;</button>
              <button onClick={() => setCurrentIndex(currentIndex + 1)} disabled={currentIndex ===(heroImagesArray.length - 1)}>&#10095;</button>
            </div>}
        
        </div> :  <SliderComponent elementsPerSlide={1} displayData={heroImagesArray} componentRender={generateHeroImage} autoplay={true} infinite={true} autoplaySpeed={5000} dots={true} slidesToScroll={1}/>}

        
        <CategoryCarousal callbackFunction={(id:any) => {navigate('/categories',{state:id})}} isHomePage={true}/>
        <div className={styles.pickup_sale_container}>
          
            <img src={dashboardImages.find((image) => isMobile ? image.imageId === 33 : image.imageId === 35)?.imageUrl} alt="Left Image" className={styles.left_veggie_container}/>
        
          <img src={dashboardImages.find((image) => isMobile ? image.imageId === 34: image.imageId === 36)?.imageUrl} className={styles.right_veggie_container} alt="Right Image"  />
        </div>
       
        <div className={styles.best_sellers_container}>
           <div className={styles.best_sellers_head}>
              <div className={styles.best_sellers_text}>
                <div>Best Sellers</div>
              </div>
              <div>
              {/* {!isMobile && <div className={styles.button_container}>
              <button onClick={prevPage} disabled={currentPage === 0}>
                  &#10094;
              </button>
              <button onClick={nextPage} disabled={nextPageInvalid}>
                   &#10095;
              </button>

              </div>} */}
              {!isMobile &&  <Button text='View All' onClick={() => {navigate('/categories')}} className={cn(styles.view_all_button,styles.best_sellers_mobile)} />}
            </div>
        </div>
          <ProductCarousal productData={productList} countPerRow={isMobile || isTablet ? 2 : 4} isMobile={isMobile}  currentPage={currentPage} nextPageStatus={(status:boolean) => {}} sliderNeeded={true}/>
            <div className={styles.top_picks_container}>
              <div className={styles.best_sellers_head}>
                <div className={styles.best_sellers_text}>
                  <div>Top Picks</div>
                </div>
                </div>
                <SliderComponent dots={true} displayData={topPicksImageArray} componentRender={topPicksItem} elementsPerSlide={isMobile ? 2 : 4} />

              
            </div>
            <div className={styles.best_sellers_container}>
              <div className={styles.best_sellers_head}>
                  <div className={styles.best_sellers_text}>
                    <div>Multi Packs</div>
                  </div>
              </div>
              <ProductCarousal productData={multipackList} countPerRow={isMobile || isTablet ? 2 : 4} isMobile={isMobile}  currentPage={currentPage} nextPageStatus={(status:boolean) => {}} sliderNeeded={true}/>
             
            </div>
            <div className={styles.best_sellers_container}>
                <div className={styles.best_sellers_head}>
                  <div className={styles.best_sellers_text}>
                    <div>Fresh Veggies</div>
                  </div>
                </div>
              <ProductCarousal productData={veggieList} countPerRow={isMobile || isTablet ? 2 : 4} isMobile={isMobile}  currentPage={currentPage} nextPageStatus={(status:boolean) => {}} sliderNeeded={true}/>
             
            </div>
            <div className={styles.best_sellers_head}>
                  <div className={styles.best_sellers_text}>
                    <div>Fresh Meat</div>
                  </div>
              </div>
              <ProductCarousal productData={meatList} countPerRow={isMobile || isTablet ? 2 : 4} isMobile={isMobile}  currentPage={currentPage} nextPageStatus={(status:boolean) => {}} sliderNeeded={true}/>
             
            <NewArrivals imageData={newArrivalsImages} isMobile={isMobile}/>
            <div className={styles.brands_container}>
              <div>
                <div>Popular Brands</div>
                <div>Explore top brands that customers love and trust.</div>
              </div>
               <div className={styles.popular_brands_grid}>
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/jacme_logo_154b79ef-a1b6-46a7-91e9-4333946b413a-ezgif.com-webp-to-png-converter-removebg-preview.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/logo-header.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/png-transparent-maggi-hd-logo-removebg-preview.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/pngwing.com9_-1.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/princefood-removebg-preview1.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/Aachi-masala.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/aashirvaad-vector-logo-removebg-preview.png'} />
                <img src={'https://arrfinefoods.in/wp-content/uploads/2024/08/images-removebg-preview.png'} />
               </div>
            </div>
        </div>
        <MobileAppLaunch />
        <Testimonials />
        {/* <Modal open={zipCodePopup} footer={false} onCancel={() => setZipCodePopup(false)}>
        <>
        <img src='https://arrfinefoods.in/wp-content/uploads/2024/09/delivery.gif' height={200} width={200} className={styles.deliver_container_image}/>
        <div className={styles.deliver_container_header}>SELECT LOCATION</div>
        <div className={styles.deliver_to_container}>
            <div>
            
            <div><span><Input placeholder='Enter ZIP Code' onChange={(e) => {handleChange(e.target.value)}} className={styles.zip_code_container}/></span></div>
            </div>
            {deliveryavailable ? <div className={styles.delivery_available_text}>Delivery available for this location</div> : deliveryavailable === false ? <div className={styles.delivery_unavailable_text}>Oops ! Delivery Unavailable for this location. Please try another zipcode</div> : ''}
        </div> 
        </>
        </Modal> */}
    </div>
  )
}

export default HomePage