import React from 'react'
import { Button as AntdButton} from 'antd';
import styles from './button.module.scss';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ShopCartIcon from '../../assets/images/Buy 3.svg'

type ButtonType = "text" | "primary" | "link" | "default" | "dashed";

type iconType = "shopcart" | ""

interface ButtonProps{
    text : string;
    onClick : any;
    className ?: string;
    type ?: ButtonType;
    iconType ?: iconType;
}

const Button = ({text,className,type='primary',iconType='',onClick}:ButtonProps) => {

  const renderIcon = () => {
    switch(iconType){
      case "shopcart":
        return <img src={ShopCartIcon} />
      default:
        return null
    }
  }
  return (
    <AntdButton className={`${styles.button_style} ${className}`} type={type} icon={renderIcon()} onClick={onClick}>{text}</AntdButton>
  )
}

export default Button