import { Button, Checkbox, Form, Input } from 'antd';
import styles from './login.module.scss';
import { signupUser } from '../../config/axios/userApis';
import { toast } from '../../components/Toaster/ToasterComponent';
import { useForm } from 'antd/es/form/Form';

type SignUpProps = {
    redirectBack: () => void
}

const Signup = ({redirectBack}:SignUpProps) => {

    const [form] = Form.useForm();

    const signUpForm = async (values:any) => {
        try {
            const values = await form.validateFields();
            console.log('All fields are filled:', values);
          } catch (errorInfo:any) {
            console.log('Fields with errors or empty:', errorInfo.errorFields);
            return;
          }
         const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password:  values.password,
            //userName: values.firstName.toLowerCase()
         }
         try{

         const signupResponse:any =  await signupUser(data);
         console.log("SUR",signupResponse);
         if(signupResponse.status === 200 ){
            window.alert("SignUp successful!");
             redirectBack();
         }
         else{
            toast.error("SignUp failed. Please try again");
         }
        }catch(e:any){
            console.log("EEEE",e?.response?.data?.message);
            alert(e?.response?.data?.message);
 
        }
    }
  return (
    <div className={styles.login_container}>
        <div className={styles.login_title}>Create Account</div>
        <div className={styles.login_details_container}>
            <Form onFinish={signUpForm} form={form}>
               <div className={styles.login_form_container}>
                  <div className={styles.signup_label}>User Name</div>
                  <Form.Item name="firstName" rules={[{required:true,message:'Please enter username'}]}>
                      <Input type='text'/>
                  </Form.Item>
               </div>
               <div className={styles.login_form_container}>
                  <div className={styles.signup_label}>Last Name</div>
                  <Form.Item name="lastName" rules={[{required:true,message:'Please enter lastname'}]}>
                      <Input type='text'/>
                  </Form.Item>
               </div>
               <div className={styles.login_form_container}>
                  <div className={styles.signup_label}>Email Id</div>
                  <Form.Item name="email" rules={[{required:true,message:'Please enter email'}]}>
                      <Input type='email'/>
                  </Form.Item>
               </div>
               <div className={styles.login_form_container}>
                  <div className={styles.signup_label}>Password</div>
                  <Form.Item name="password" rules={[{required:true,message:'Please enter password'},{
                    pattern:/^[A-Za-z0-9!@#]{8}$/,
                    message: 'Password must be 8 characters long and should contain only alphanumeric and ! @ # as special characters.'
                  }]}>
                      <Input type='password'/>
                  </Form.Item>
               </div>
               <div className={styles.password_criteria}>Password must be 8 characters long and should contain only alphanumeric and ! @ # as special characters.</div>
               <div className={styles.login_form_container}>
                  <div className={styles.signup_label}>Mobile Number</div>
                  <Form.Item name="mobile" rules={[{required:true,message:'Please enter mobilenumber'}]}>
                      <Input type='number'/>
                  </Form.Item>
               </div>
               <div className={styles.checkbox_container}>
                 <Checkbox />
                 <div>Email me offers and special promotions.</div>
               </div>
               <div className={styles.create_account_prompt}>By pressing Create Account, you acknowledge you have read and agree to our <u><b>Terms of Use,</b></u></div>
               <div className={styles.signup_button_container}><Button type='primary' htmlType='submit'>Create Account</Button>
               or <span className={styles.redirect_back_to_login} onClick={redirectBack}>Sign In</span></div>
            </Form>
        </div>
    </div>
  )
}

export default Signup