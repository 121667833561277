import { Card } from 'antd';
import styles from './blogCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { BlogType } from '../../views/BlogPage/BlogPage';

interface BlogCardProps{
  blogData: BlogType;
}

const BlogCard = ({blogData}:BlogCardProps) => {

  const navigate = useNavigate();

  return (
    <Card onClick={() => {navigate(`/blog/${blogData.id}`)}}hoverable={true} bordered={false} cover={<div>
      <img alt='blog card image' src={blogData.imageUrl} className={styles.blog_card_cover_image}/>
    </div>} className={styles.blog_card}>
        <div className={styles.card_content}>
          <div className={styles.blog_tag}>Blog Tag</div>
          <div className={styles.blog_title}><b>{blogData.description}</b></div>
          <div>{blogData.title}</div>
        </div>
    </Card>
  )
}

export default BlogCard