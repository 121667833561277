import { Button, Input } from 'antd';
import styles from './contactUs.module.scss';


const ContactUsPage = () => {

    const defaultProps = {
        center: {
          lat: 50.40363950777543,
          lng: -4.187318560922885   
        },
        zoom: 11
      };

  return (
    <div className={styles.contact_us_container}>
        <div className={styles.contact_page_header}>Contact Us</div>
        <div className={styles.contact_details_section_container}>
            <div className={styles.contact_details_container}>
                <div className={styles.contact_detail}>
                    <div>Address:</div>
                    <div className="contact-detail">14-16 Victoria Rd, St Budeaux, Plymouth PL5 1RG, United Kingdom</div>
                </div>
                <div className={styles.contact_detail}>
                    <div>Working Hours:</div>
                    <div className="contact-detail">Monday – Sunday: 08:00 - 20:00</div>
                </div>
                <div className={styles.contact_detail}>
                    <div>Phone:</div>
                    <div className="contact-detail">+44 1752 271528</div>
                </div>
                <div className={styles.contact_detail}>
                    <div>Email:</div>
                    <div className="contact-detail">arrretailltd@gmail.com</div>
                </div>
                
            </div>
                <div className={styles.form_container}>
                    <form className={styles.contact_form}>
                        <div className={styles.form_group}>
                            <label htmlFor="name">Name:</label>
                            <Input type="text" id="name" required />
                        </div>
                        <div className={styles.form_group}>
                            <label htmlFor="email">Email:</label>
                            <Input type="email" id="email" required />
                        </div>
                        <div className={styles.form_group}>
                            <label htmlFor="subject">Subject:</label>
                            <Input type="text" id="subject" required />
                        </div>
                        <div className={styles.form_group}>
                            <label htmlFor="message">Message:</label>
                            <Input.TextArea id="message" required />
                        </div>
                        <div className={styles.form_group}>
                            <Button htmlType='submit' type='primary'>Submit </Button>
                        </div>
                    </form>
                </div>
        </div>

    </div>
  )
}

export default ContactUsPage