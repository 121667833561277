import axios from "axios"

export const getOrderDetails = async (userId:string) => {
    try{
    return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/order/details/${userId}`,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
        }
    })
    }catch(err){
        return err;
    }
    
}

export const getShipmentFee = async (userId:string,zipCode:string,price:number) => {
    try{
        return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/delivery/${userId}/${zipCode}/${Math.round(price)}`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
            }
        })

    }catch(err){
        return err;
    }

}

export const emailTriggerApi = async (userId:string,orderId:string,addressId:string) => {

    try{
       return await axios.post(`${process.env.REACT_APP_ARRFINE_BASE_URL}/users/mail/${userId}/${orderId}?addid=${addressId}`,{},{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
        }
 
       })
    }catch(err){
        return err;
    }
}

export const getOrderDetailsFromOrderId = async (orderId:string) => {
    try{
        return await axios.get(`${process.env.REACT_APP_ARRFINE_BASE_URL}/orders/${orderId}`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')!.slice(1,-1)}`
            }
        })
    }catch(err){
        return err;
    }
}