import './App.css';
import Header from './layouts/Header/Header';
import Footer from './layouts/Footer/Footer';
import MainLayout from './layouts/Main/MainLayout';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider, WishListProvider } from './config/context/CartContext';
import TopLevelMessage from './components/TopLevelMessage/TopLevelMessage';
import { ToasterComponent } from './components/Toaster/ToasterComponent';
import { useEffect } from 'react';
import { ProductProvider } from './config/context/ProductContext';

function App() {

  useEffect(() => {
    return () => {
      // cleanup code
      localStorage.removeItem('popupShown');
    }
  })

  return (
    <div className="App">
     <BrowserRouter>
        <CartProvider>
          <WishListProvider>
            <ProductProvider>
              <TopLevelMessage />
              <Header />
              <MainLayout />
              <ToasterComponent />
              <Footer />
            </ProductProvider>
          </WishListProvider>
        </CartProvider>
     </BrowserRouter>
    </div>
  );
}

export default App;
