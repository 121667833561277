import { Dropdown, MenuProps } from "antd"
import styles from './header.module.scss';
import VeggiesPic from '../../assets/images/Rectangle 28_veggies_pic.png'
import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

interface CategoriesDropdownProps {
    items: any
}

const CategoriesDropdown = ({items}: CategoriesDropdownProps) => {

    const [isDropdownActive, setIsDropDownActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

  return (
    <>
        <div onMouseEnter={() => {setIsDropDownActive(true)}} onMouseLeave={() => {setIsDropDownActive(false)}} className={styles.dropdown_wrapper}>Shop {isDropdownActive ? <UpOutlined className={styles.arrow_icon} /> : <DownOutlined className={styles.arrow_icon} />}
        {
            isDropdownActive && <div className={`${styles.dropdown_menu_container}`} onMouseEnter={() => {setIsDropDownActive(true)}} onMouseLeave={() => {setIsDropDownActive(false)}} >
                <div>
                    <div className={styles.dropdown_items_container}>
                        {
                            items?.map((item:any) => <div className={styles.dropdown_menu_item} onClick={() => {item?.onClick();setIsDropDownActive(false)}}>{item.label.charAt(0).toUpperCase() + item.label.slice(1).toLowerCase()}</div>)
                        }
                    </div>
                </div>
                <div className={styles.dropdown_image_container}>
                    <img src={VeggiesPic} className={styles.veggie_pic} />
                    <div>
                        <b>Shop Like a Hero 
                        Fresh, Healthy Triumphs Await!</b>
                        <div>Unleash the Shopper's Valor: Where Every Cart is a Chariot and Every Fresh, Healthy Purchase a Triumph</div>
                    </div>
                </div>

            </div>
        }

    </div>
        
    </>
  )
}

export default CategoriesDropdown