import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import styles from './header.module.scss'
import { useState } from 'react';

interface HamburgerBarProps {
    activeTab : string;
    hamburgerMenu : boolean;
    onCloseFunction : () => void;
    onOptionClickFunction : (option:string,categoryId?:number) => void;
    categoriesList : any;
}

const HamburgerBar = ({activeTab,hamburgerMenu,onCloseFunction,onOptionClickFunction,categoriesList}:HamburgerBarProps) => {

    const [shopDropdown, setShopDropdown] = useState<boolean>(false);

  return (
    <div className={!hamburgerMenu ? styles.hamburger_list : styles.hamburger_list_active}>
    <div className={styles.hamburger_header}>
      <div>Menu</div>
      <CloseOutlined onClick={() => onCloseFunction()}/>
    </div>

    <div className={activeTab === 'home' ? styles.active_tab : ''} onClick={() => {onOptionClickFunction('home')}} >Home</div>
    <div className={activeTab === 'shop' ? styles.active_tab : ''} onClick={() => {setShopDropdown(!shopDropdown)}}>Shop</div>
        {
            shopDropdown && <div className={styles.hamburger_category_container}>
                {
                    categoriesList.map((category:any) => <div className={`${styles.dropdown_menu_item} ${styles.hamburger_menu_item}`} onClick={() => {onOptionClickFunction('shop',category.id)}} >{category.label.charAt(0).toUpperCase() + category.label.slice(1).toLowerCase()} <RightOutlined className={styles.right_icon}/></div>)
                }
            </div>
        }
    <div className={activeTab === 'multipack' ? styles.active_tab : ''} onClick={() => {onOptionClickFunction('multipack')}}>Multipacks</div> 
    <div className={activeTab === 'newarrivals' ? styles.active_tab : ''} onClick={() => {onOptionClickFunction('newarrivals')}}>New Arrivals</div>
    <div className={activeTab === 'about' ? styles.active_tab : ''} onClick={() => {onOptionClickFunction('about')}}>About Us</div>
    <div className={activeTab === 'blog' ? styles.active_tab : ''} onClick={() => {onOptionClickFunction('blog')}}>Blog</div>
    <div className={activeTab === 'contact' ? styles.active_tab : ''} onClick={() => {onOptionClickFunction('contact')}}>Contact Us</div>
  </div>
  )
}

export default HamburgerBar