import {Card} from "antd"
import { useContext, useEffect, useState } from "react";
import styles from './productCarousal.module.scss';
import { Link } from "react-router-dom";
import { HeartFilled } from "@ant-design/icons";
import { CartContext, WishListContext } from "../../config/context/CartContext";
import Button from "../Button/Button";
import { chunkArray } from "../../utils/chunkArray";
import SliderComponent from "../SliderComponent/SliderComponent";

export interface ProductCarousalProps {
    productData : any,
    countPerRow: number,
    isMobile : boolean,
    currentPage: number,
    nextPageStatus : (status:boolean) => void,
    sliderNeeded: boolean,
    dots ?:boolean,
}

const ProductCarousal = ({productData,countPerRow,isMobile,currentPage,nextPageStatus,sliderNeeded,dots=false}:ProductCarousalProps) => {

    const {addToCart} = useContext(CartContext);
    const {wishListItems,addTowishList,removeFromwishList} = useContext(WishListContext)

    const checkIteminWishList = (item:any) => {
        return wishListItems.some((sampleItem) => sampleItem.id === item.id)
    }

    const renderSlide = (item:any) => {
       return <div key={item.id} className={styles.carouselItem}>
       <div className={styles.card_link}>
              <Card hoverable={true} bordered={true} className={styles.homepage_product_card} cover={<div className={styles.card_image_background}>
              <img alt='Product Image' src={item.imageUrl} className={styles.product_image}/>
              <HeartFilled className={`${styles.like_icon} ${checkIteminWishList(item) ? styles.wishlist_icon : ''}`} onClick={() => {checkIteminWishList(item) ? removeFromwishList(item) : addTowishList(item)}}/>
              </div>}>
                  <div>
                      <div className={styles.best_seller_label}>Best Seller</div>
                      <Link to={`/product/${item.id}`}><span className={styles.product_card_title}>{item.title}</span></Link>
                      {/* <div>{item.description}</div> */}
                      <div className={styles.product_price_container}>
                          <div>{`\u00A3`} {item.price}</div>
                          <Button onClick={() => {addToCart(item)}} text="Add Cart" className={styles.add_cart_button} iconType="shopcart"/>
                      </div>
                  </div>
              </Card>
              </div>
    </div>
    }


      useEffect(() => {
        if(currentPage >= chunks.length - 1) {
              nextPageStatus(true);
        }
        else{
          nextPageStatus(false)
        }
      },[currentPage])

      const chunks = chunkArray(productData, countPerRow);


        return (
          <>
            <div className={sliderNeeded ? styles.slider_carousal: styles.carousel}>
                  {/* Use class from the SCSS module */}
              {/* Render the current chunk */}
              
              {sliderNeeded ? <SliderComponent displayData={productData} elementsPerSlide={countPerRow} swipeToSlide={true} dots={dots} componentRender={renderSlide} slidesToScroll={isMobile ? 2 : 1} /> : <div className={styles.carouselContainer}>
                {chunks[currentPage].map((item:any, index:number) => (
                  <div key={index} className={styles.carouselItem}>
                     <Link to={`/product/${item.id}`} className={styles.card_link}>
                            <Card hoverable={true} bordered={true} className={styles.homepage_product_card} cover={<div className={styles.card_image_background}>
                            <img alt='Product Image' src={item.imageUrl} className={styles.product_image}/>
                            <HeartFilled className={`${styles.like_icon} ${checkIteminWishList(item) ? styles.wishlist_icon : ''}`} onClick={() => {checkIteminWishList(item) ? removeFromwishList(item) : addTowishList(item)}}/>
                            </div>}>
                                <div>
                                    <div className={styles.best_seller_label}>Best Seller</div>
                                    <div className={styles.product_card_title}>{item.title}</div>
                                    {/* <div>{item.description}</div> */}
                                    <div className={styles.product_price_container}>
                                        <div>{`\u00A3`} {item.price}</div>
                                        <Button onClick={() => {addToCart(item)}} text="Add Cart" className={styles.add_cart_button} iconType="shopcart"/>
                                    </div>
                                </div>
                            </Card>
                            </Link>
                  </div>
                ))}
              </div>}
        

              
               
                
             
            </div>
            </>
          );
}

export default ProductCarousal