import { createContext, ReactNode, useState } from "react"


export interface ProductType {
    productId:number,
    isSale: boolean,
    discountPrice:number
}

interface ProductContextType {
  productDetails: ProductType[],
  addProduct: (product:ProductType) => void,
  addProductArray: (productArray:ProductType[]) => void
}

export const ProductContext = createContext<ProductContextType>({
    productDetails: [],
    addProduct: (product:ProductType) => {},
    addProductArray: (productArray) => {
        
    },
})

interface ProductProviderProps {
    children: ReactNode;
}

export const ProductProvider: React.FC<ProductProviderProps> = ({children}) => {
    const [productDetails,setProductDetails] = useState<ProductType[]>([]);
    const addProduct = (product:ProductType) => {
        setProductDetails((prev) => {
            const updatedProducts = [...prev, product];
          
            // Remove duplicates based on product ID
            const uniqueProducts = updatedProducts.filter((value, index, self) => 
              index === self.findIndex((t) => (
                t.productId === value.productId // Assuming 'id' is the unique identifier for a product
              ))
            );
          
            return uniqueProducts;
          });
    }
    const addProductArray = (productArray:ProductType[]) => {
        setProductDetails((prev) => [...prev,...productArray]);
    }
    return <ProductContext.Provider value={{productDetails,addProduct,addProductArray}}>{children}</ProductContext.Provider>
}