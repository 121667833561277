import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { toast } from '../../components/Toaster/ToasterComponent';
import { percentageCalculator } from '../../utils/calculatePercentage';

interface Item {
  id: number;
  price: number;
  quantity: number;
  title:string;
  description:string;
  imageUrl:string;
}

interface CartContextType {
  cartItems: Item[];
  addToCart: (item: Item,quantity?:number) => void;
  removeFromCart: (item: Item) => void;
  clearCart: () => void;
  getCartTotal: () => number;
  getTotalPaymentAmount: () => number;
  getCartTotalItems: () => number;
  addToCartFromCartPage:(item: Item,quantity:number) => void;
  shipmentFee:number;
  setShipmentFee: (fee:number) => void;
  placedOrderId: string;
  setPlacedOrderId: (orderId: string) => void;
  deliveryAddressId: string;
  setDeliveryAddressId: (addressId: string) => void;
  zipCode: string;
  setZipCode: (zipCode: string) => void;
  
}

interface WishListContextType {
  wishListItems: Item[];
  addTowishList: (item: Item, quantity?:number) => void;
  removeFromwishList: (item: Item) => void;
  clearwishList: () => void;
  getwishListTotal: () => number;
  getwishListTotalItems: () => number;
  addTowishListFromwishListPage:(item: Item,quantity:number) => void;
  moveWishListItemstoCart: () => void;
}

export const CartContext = createContext<CartContextType>({
  cartItems: [],
  addToCart: () => {},
  removeFromCart: () => {},
  clearCart: () => {},
  getCartTotal: () => 0,
  getCartTotalItems: () => 0,
  getTotalPaymentAmount: () => 0,
  addToCartFromCartPage: () => {},
  shipmentFee: 0,
  setShipmentFee: () => {},
  placedOrderId: '',
  setPlacedOrderId: () => {},
  deliveryAddressId: '',
  setDeliveryAddressId: () => {},
  zipCode: '',
  setZipCode: () => {},
  
});

export const WishListContext = createContext<WishListContextType>({
  wishListItems: [],
  addTowishList: () => {},
  removeFromwishList: () => {},
  clearwishList: () => {},
  getwishListTotal: () => 0,
  getwishListTotalItems: () => 0,
  addTowishListFromwishListPage: () => {},
  moveWishListItemstoCart: () => {}
})

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState<Item[]>(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });
  const [shipmentFee, setShipmentFee] = useState(0);
  const [placedOrderId, setPlacedOrderId] = useState<string>('');
  const [deliveryAddressId, setDeliveryAddressId] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  const addToCartFromCartPage = (item: Item,quantity:number) => {
    setCartItems((prevCartItems) =>
    prevCartItems.map((cartItem) =>
      cartItem.id === item.id ? { ...cartItem, quantity: quantity } : cartItem
    )
  );
  }

  const addToCart = (item: Item,quantity?:number) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart) {
      setCartItems((prevCartItems) =>
        prevCartItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + (quantity ? quantity : 1) } : cartItem
        )
      );
    } else {
      setCartItems((prevCartItems) => [...prevCartItems, { ...item, quantity: (quantity ? quantity : 1) }]);
    }
    toast.info(`Product ${item.title} Added to cart`)
  };

  const removeFromCart = (item: Item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    // if (isItemInCart && isItemInCart.quantity === 1) {
    //   setCartItems((prevCartItems) => prevCartItems.filter((cartItem) => cartItem.id !== item.id));
    // } else {
    //   setCartItems((prevCartItems) =>
    //     prevCartItems.map((cartItem) =>
    //       cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
    //     )
    //   );
    // }

    if(isItemInCart){
      setCartItems((prevCartItems) => prevCartItems.filter((cartItem) => cartItem.id !== item.id));
    }

  };

  const clearCart = () => {
    setCartItems([]);
  };

  const getCartTotal = (discount = 0) => {
    if(discount > 0){
      const cartTotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
      return +percentageCalculator(cartTotal,discount);
    }
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getTotalPaymentAmount = () => {
    const cartTotal = getCartTotal();
    if(cartTotal < 35){
      return cartTotal + 5
    }
    return cartTotal
  }

  const getCartTotalItems = () =>{
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  }

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        getTotalPaymentAmount,
        getCartTotalItems,
        addToCartFromCartPage,
        shipmentFee,
        setShipmentFee,
        placedOrderId,
        setPlacedOrderId,
        deliveryAddressId,
        setDeliveryAddressId,
        zipCode,
        setZipCode,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const WishListProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [wishListItems, setwishListItems] = useState<Item[]>(() => {
    const storedwishListItems = localStorage.getItem('wishListItems');
    return storedwishListItems ? JSON.parse(storedwishListItems) : [];
  });

  const {addToCart} = useContext(CartContext);

  const addTowishListFromwishListPage = (item: Item,quantity:number) => {
    setwishListItems((prevwishListItems) =>
    prevwishListItems.map((wishListItem) =>
      wishListItem.id === item.id ? { ...wishListItem, quantity: quantity } : wishListItem
    )
  );
  }

  const addTowishList = (item: Item,quantity?:number) => {
    const isItemInwishList = wishListItems.find((wishListItem) => wishListItem.id === item.id);

    if (isItemInwishList) {
      setwishListItems((prevwishListItems) =>
        prevwishListItems.map((wishListItem) =>
          wishListItem.id === item.id ? { ...wishListItem, quantity: wishListItem.quantity + (quantity ? quantity : 1) } : wishListItem
        )
      );
    } else {
      setwishListItems((prevwishListItems) => [...prevwishListItems, { ...item, quantity: (quantity ? quantity : 1) }]);
    }
    toast.info(`Product ${item.title} Added to wishlist`)
  };

  const removeFromwishList = (item: Item) => {
    const isItemInwishList = wishListItems.find((wishListItem) => wishListItem.id === item.id);

    if (isItemInwishList && isItemInwishList.quantity === 1) {
      setwishListItems((prevwishListItems) => prevwishListItems.filter((wishListItem) => wishListItem.id !== item.id));
    } else {
      setwishListItems((prevwishListItems) =>
        prevwishListItems.map((wishListItem) =>
          wishListItem.id === item.id ? { ...wishListItem, quantity: wishListItem.quantity - 1 } : wishListItem
        )
      );
    }
  };

  const clearwishList = () => {
    setwishListItems([]);
  };

  const getwishListTotal = () => {
    return wishListItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getwishListTotalItems = () =>{
    return wishListItems.reduce((total, item) => total + item.quantity, 0);
  }

  const moveWishListItemstoCart = () => {
    wishListItems.forEach((item) => {
      addToCart(item);
    })
    clearwishList();
    localStorage.removeItem('wishListItems');
    
  }

  useEffect(() => {
    localStorage.setItem('wishListItems', JSON.stringify(wishListItems));
  }, [wishListItems]);

  useEffect(() => {
    const storedwishListItems = localStorage.getItem('wishListItems');
    if (storedwishListItems) {
      setwishListItems(JSON.parse(storedwishListItems));
    }
  }, []);

  return (
    <WishListContext.Provider
      value={{
        wishListItems,
        addTowishList,
        removeFromwishList,
        clearwishList,
        getwishListTotal,
        getwishListTotalItems,
        addTowishListFromwishListPage,
        moveWishListItemstoCart
      }}
    >
      {children}
    </WishListContext.Provider>
  );
};
