import { HeartFilled } from '@ant-design/icons'
import { Card } from 'antd'
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './productCard.module.scss';
import { CartContext, WishListContext } from '../../config/context/CartContext';
import Button from '../Button/Button';
import SaleBadge from '../../assets/images/Rectangle 630.png';
import { percentageCalculator } from '../../utils/calculatePercentage';

interface ProductCardProps {
    item:any,
    isSale: boolean,
    discountPrice ?: number
}

const ProductCard = ({item,isSale,discountPrice=0}:ProductCardProps) => {

    const {addToCart} = useContext(CartContext);
    const {wishListItems,addTowishList,removeFromwishList} = useContext(WishListContext)
  
    const checkIteminWishList = (item:any) => {
      return wishListItems.some((sampleItem) => sampleItem.id === item.id)
  }

  const productItem = JSON.parse(JSON.stringify(item));


 
  if(isSale){
    productItem.price = +percentageCalculator(productItem.price,discountPrice);
  }
 

  return (
    <Link to={`/product/${item.id}`} className={styles.card_link}>
    <Card hoverable={true} bordered={true} className={styles.homepage_product_card} cover={<div className={styles.card_image_background}>
    <img alt='Product Image' src={item.imageUrl} className={styles.product_image}/>
    <HeartFilled className={`${styles.like_icon} ${checkIteminWishList(item) ? styles.wishlist_icon : ''}`} onClick={() => {checkIteminWishList(item) ? removeFromwishList(item) : addTowishList(item)}}/>
    {isSale && discountPrice > 0 && <><img src={SaleBadge} className={styles.sale_badge} /><span className={styles.inner_text}>{discountPrice}% Off</span></>}
    </div>}>
        <div>
            <div className={styles.best_seller_label}>Best Seller</div>
            <div className={styles.product_card_title}>{item.title}</div>
            {/* <div>{item.description}</div> */}
            <div className={styles.product_price_container}>
               {discountPrice > 0 ? <div className={styles.product_discount_price_container}>
                <div className={styles.og_price}>{`\u00A3`} {item.price}</div>
                <div>{`\u00A3`} {percentageCalculator(item.price,discountPrice)}</div>
               </div> : <div>{`\u00A3`} {item.price}</div>}
                <Button onClick={() => {console.log('PI',productItem);addToCart(productItem)}} text="Add Cart" className={styles.add_cart_button} iconType="shopcart"/>
            </div>
        </div>
    </Card>
    </Link>
  )
}

export default ProductCard