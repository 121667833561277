import Slider from "react-slick";
import styles from './sliderComponent.module.scss';


export interface SliderComponentProps {
    displayData: any;
    componentRender: (item:any) => React.ReactNode;
    elementsPerSlide: number;
    dots?: boolean;
    infinite ?:boolean;
    autoplay?:boolean;
    autoplaySpeed ?: number;
    swipeToSlide ?:boolean;
    slidesToScroll ?: number;
    className?:string;
    pauseOnHover?:boolean;

}

const SliderComponent = ({displayData,componentRender,elementsPerSlide,dots=false,infinite=false,autoplay=false,autoplaySpeed=0,swipeToSlide=false,slidesToScroll=1,className='',pauseOnHover=false}:SliderComponentProps) => {

    const settings = {
        dots,
        infinite,
        autoplay,
        autoplaySpeed,
        speed:2000,
        swipeToSlide,
        slidesToShow: elementsPerSlide,
        slidesToScroll,
        adaptiveHeight:false,
        pauseOnHover
    }

  return (
    <Slider {...settings} className={`${className} ${styles.slider_component}`}>
      {
        displayData.map((item:any) => componentRender(item))
      }

    </Slider>
  )
}

export default SliderComponent