import { AppleOutlined } from '@ant-design/icons'
import PlayStoreIcon from '../../assets/images/Icon_playstore.svg'
import MobileImageOutline from '../../assets/images/Rectangle 62_mobile_outline.svg'
import styles from './homepage.module.scss'
import { useEffect, useState } from 'react'

const MobileAppLaunch = () => {

    const [isMobile, setIsMobile] = useState<boolean>(false);

     //choose the screen size 
     const handleResize = () => {
        if (window.innerWidth < 720) {
            console.log('It is a mobile screen')
            setIsMobile(true)
        } else {
            console.log('It is not a mobile screen')
            setIsMobile(false)
        }
    }

    useEffect(() => {
        handleResize();
    },[])

  return (
    <div className={styles.mobile_launch_container}>
    <div>
        <div>
                <h3>Download Fresh Market Mobile App</h3>
                <div>Get the best shopping experience on your mobile device.</div>
        </div>
        <div className={styles.download_buttons_container}>
            <div>
                <AppleOutlined className={styles.apple_icon}/>
                <div>Download on the <span>APP Store</span></div>
            </div>
            <div>
                <img src={PlayStoreIcon} style={{height:'27px'}} />
                <div>Get it on <span>Google Play</span></div>
            </div>
        </div>
    </div>
   {!isMobile && <div>
        <img src={"https://arrfinefoods.in/wp-content/uploads/2025/02/01-Free-Galaxy-S24-Ultra-Mockup-On-Cube-Stones.png"} className={styles.mobile_app_launch_image}/>
    </div>}
</div>
  )
}

export default MobileAppLaunch